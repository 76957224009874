import React, { useState, useEffect } from 'react'
import tw, { css, styled } from 'twin.macro'
import useLocale from '../hooks/useLocale'
import VisuallyHidden from '@reach/visually-hidden'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { BLOCKS } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { GET_BANNER } from './queries/hero'
import filterLocale from './utils/filter-locale'
import Meta from '../components/meta'
const windowGlobal = typeof window !== 'undefined' && window

import img1WebP from '../images/maytronics_dolphin_m600_robotic_pool_cleaner_climbing_pool_wall_underwater.webp'
import img1JPEG from '../images/maytronics_dolphin_m600_robotic_pool_cleaner_climbing_pool_wall_underwater.jpeg'
import img2WebP from '../images/kid_with_yellow_cape_pretending_to_fly_in_front_of_pool.webp'
import img2JPEG from '../images/kid_with_yellow_cape_pretending_to_fly_in_front_of_pool.jpeg'

const Section = styled.section`
	${tw`relative overflow-hidden`}
	background: linear-gradient(90deg, rgba(49,55,67,1) 0%, rgba(27,125,154,1) 50%);
`
const Wrapper = styled.div`
	${tw`w-full lg:w-6/12`}
`
const Container = styled.div`
	${tw`relative z-10 pb-8 w-full py-16 xl:py-40`}
`
const Controls = tw.div`pl-4 sm:pl-8 lg:pl-10 xl:pl-6 xxl:pl-8 xxxl:pl-12 flex items-center mb-6 xl:mb-0`
const Nav = tw.nav`relative flex items-center justify-between sm:h-10 lg:justify-start`
const Dots = styled.div`
	${tw`flex flex-row xl:flex-col items-center flex-grow flex-shrink-0 lg:flex-grow-0 text-gray-500 mt-px`}
	button {
		&:first-of-type {
			${tw`mr-2 xl:mr-0 xl:mb-6`}
		}
		${tw`border-t-0 border-l-0 border-b-2 border-r-2 border-blue-600 bg-gray-200 hover:bg-white hover:border-blue-400`}
		${tw`w-4 h-4 sm:w-6 sm:h-6 rounded-full focus:outline-none focus:shadow-outline`}
		&:hover {
			${tw`border-t-2 border-l-2 border-b-2 border-r-2`}
		}
		&.isActive {
			${tw`bg-gray-200`}
			box-shadow: #58c8df 0 0 4px;
			${tw`border-t-2 border-l-2 border-b-2 border-r-2 border-blue-500`}
		}
	}
`
const TextWrapper = tw.div`w-full flex flex-col xl:flex-row`
const Text = styled.div`
	${tw`text-center lg:text-left relative lg:z-20 px-4 sm:px-8 lg:px-10 xl:pr-4 xxxl:pr-12 xl:pl-6 xxl:pl-8 xxxl:pl-12`}
	h2 {
		${tw`text-3xl md:text-5xl xxxl:text-6xl tracking-tight leading-10 font-bold font-display text-white sm:leading-none xxl:leading-tight xxxl:leading-none`}
		/* @media (min-width:1280px) and (max-width:1399px) {
			font-size: 2.6rem;
		}
		@media (min-width:768px) {
			font-size: 2.8rem;
		} */
		span {
			${tw`text-white`}
		}
	}
	p {
		${tw`mt-3 text-base text-white sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0`}
	}
	.group {
		${tw`mt-5 mx-auto flex md:block flex-col`}
		button {
			${tw`transition duration-300 ease-in-out w-56 md:w-auto mx-auto`}
			a {
				${tw`w-full flex items-center justify-center px-6 py-2 border border-transparent text-base leading-6 font-medium rounded-md focus:outline-none transition duration-300 ease-in-out xl:py-3 xl:text-lg xl:px-8`}
				&:focus {
					box-shadow: 0 0 0 3px rgba(65, 201, 225, 0.5);
				}
			}
			&.solid {
				${tw`rounded-md shadow hover:shadow-none`}
				a {
					${tw`bg-yellow-500 hover:bg-yellow-400 uppercase`}
				}
			}
			&.outline {
				${tw`mt-3 ml-auto md:ml-3 md:mt-0 rounded-md shadow-none`}
				a {
					${tw`text-white uppercase bg-transparent hover:text-current hover:bg-blue-50 border-2 border-blue-200 hover:border-blue-50`}
				}
			}
		}
	}
`
const Image = styled.div`
	${tw`lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2`}
	img {
		${tw`h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full`}
	}
`

const Hero2 = ({ locale }) => {
	const {
		firstBanner,
		firstmd,
		firstsm,
		firstxs,
		firstxxs,
		secondBanner,
		secondmd,
		secondsm,
		secondxs,
		secondxxs,
	} = useStaticQuery(graphql`
		query homeHeroBannersQuery {
			firstBanner: allContentfulBanner(
				filter: { contentful_id: { eq: "75VuNhVVpVH5U7Jiv2KM3G" } }
			) {
				edges {
					node {
						mainActionText
						mainActionUrl
						secondActionText
						secondActionUrl
						node_locale
						hook
						description {
							json
						}
						backgroundImage {
							description
							fluid(
								maxWidth: 700
								quality: 80
								cropFocus: LEFT
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}
					}
				}
			}
			firstmd: allContentfulBanner(
				filter: { contentful_id: { eq: "75VuNhVVpVH5U7Jiv2KM3G" } }
			) {
				edges {
					node {
						node_locale
						backgroundImage {
							fluid(
								maxWidth: 600
								quality: 80
								cropFocus: CENTER
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}
					}
				}
			}
			firstsm: allContentfulBanner(
				filter: { contentful_id: { eq: "75VuNhVVpVH5U7Jiv2KM3G" } }
			) {
				edges {
					node {
						node_locale
						backgroundImage {
							fluid(
								maxWidth: 500
								quality: 80
								cropFocus: CENTER
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}
					}
				}
			}
			firstxs: allContentfulBanner(
				filter: { contentful_id: { eq: "75VuNhVVpVH5U7Jiv2KM3G" } }
			) {
				edges {
					node {
						node_locale
						backgroundImage {
							fluid(
								maxWidth: 400
								quality: 80
								cropFocus: CENTER
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}
					}
				}
			}
			firstxxs: allContentfulBanner(
				filter: { contentful_id: { eq: "75VuNhVVpVH5U7Jiv2KM3G" } }
			) {
				edges {
					node {
						node_locale
						backgroundImage {
							fluid(
								maxWidth: 350
								quality: 80
								cropFocus: CENTER
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}
					}
				}
			}
			secondBanner: allContentfulBanner(
				filter: { contentful_id: { eq: "7IXompgH7PGnBQesV5VGG1" } }
			) {
				edges {
					node {
						mainActionText
						mainActionUrl
						secondActionText
						secondActionUrl
						node_locale
						hook
						description {
							json
						}
						backgroundImage {
							fluid(
								maxWidth: 700
								quality: 80
								cropFocus: TOP
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}
					}
				}
			}
			secondmd: allContentfulBanner(
				filter: { contentful_id: { eq: "7IXompgH7PGnBQesV5VGG1" } }
			) {
				edges {
					node {
						node_locale
						backgroundImage {
							fluid(
								maxWidth: 600
								quality: 80
								cropFocus: TOP
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}
					}
				}
			}
			secondsm: allContentfulBanner(
				filter: { contentful_id: { eq: "7IXompgH7PGnBQesV5VGG1" } }
			) {
				edges {
					node {
						node_locale
						backgroundImage {
							fluid(
								maxWidth: 500
								quality: 80
								cropFocus: TOP
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}
					}
				}
			}
			secondxs: allContentfulBanner(
				filter: { contentful_id: { eq: "7IXompgH7PGnBQesV5VGG1" } }
			) {
				edges {
					node {
						node_locale
						backgroundImage {
							fluid(
								maxWidth: 400
								quality: 80
								cropFocus: TOP
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}
					}
				}
			}
			secondxxs: allContentfulBanner(
				filter: { contentful_id: { eq: "7IXompgH7PGnBQesV5VGG1" } }
			) {
				edges {
					node {
						node_locale
						backgroundImage {
							fluid(
								maxWidth: 350
								quality: 80
								cropFocus: TOP
								resizingBehavior: FILL
							) {
								src
								srcWebp
							}
						}
					}
				}
			}
		}
	`)
	const [{ node: first }] = filterLocale(firstBanner.edges, locale)
	const [{ node: firstmed }] = filterLocale(firstmd.edges, locale)
	const [{ node: firstsmall }] = filterLocale(firstsm.edges, locale)
	const [{ node: firstExtraSmall }] = filterLocale(firstxs.edges, locale)
	const [{ node: firstExtraExtraSmall }] = filterLocale(firstxxs.edges, locale)
	const [{ node: second }] = filterLocale(secondBanner.edges, locale)
	const [{ node: secondmed }] = filterLocale(secondmd.edges, locale)
	const [{ node: secondsmall }] = filterLocale(secondsm.edges, locale)
	const [{ node: secondExtraSmall }] = filterLocale(secondxs.edges, locale)
	const [{ node: secondExtraExtraSmall }] = filterLocale(
		secondxxs.edges,
		locale
	)
	const sources = [
		[
			{
				src: img1JPEG,
				srcSet: img1JPEG,
				srcSetWebp: img1WebP,
				title: 'Dolphin M 600 robot underwater',
				alt: 'A Maytronics Dolphin M 600 sitting underwater on the pool floor',
				loading: 'eager',
			},
		],
		[
			{
				src: img2JPEG,
				srcSet: img2JPEG,
				srcSetWebp: img2WebP,
				title: 'Boy with yellow cape',
				alt:
					'Young boy with a yellow cape pretends to fly infront of pool edge',
				loading: 'lazy',
			},
		],
	]
	const contentfulSources = [
		[
			{
				...first.backgroundImage.fluid,
				srcSet: `${first.backgroundImage.fluid.src} 1920w`,
				srcSetWebp: `${first.backgroundImage.fluid.srcWebp} 1920w`,
				media: `(min-width: 1350px)`,
			},
			{
				...firstmed.backgroundImage.fluid,
				srcSet: `${firstmed.backgroundImage.fluid.src} 1024w`,
				srcSetWebp: `${firstmed.backgroundImage.fluid.srcWebp} 1024w`,
				media: `(min-width: 1024px) and (max-width: 1349px)`,
			},
			{
				...firstsmall.backgroundImage.fluid,
				srcSet: `${firstsmall.backgroundImage.fluid.src} 768w`,
				srcSetWebp: `${firstsmall.backgroundImage.fluid.srcWebp} 768w`,
				media: `(min-width: 768px) and (max-width: 1023px)`,
			},
			{
				...firstExtraSmall.backgroundImage.fluid,
				srcSet: `${firstExtraSmall.backgroundImage.fluid.src} 525w`,
				srcSetWebp: `${firstExtraSmall.backgroundImage.fluid.srcWebp} 525w`,
				media: `(min-width: 525px) and (max-width: 767px)`,
			},
			{
				...firstExtraExtraSmall.backgroundImage.fluid,
				srcSet: `${firstExtraExtraSmall.backgroundImage.fluid.src} 410w`,
				srcSetWebp: `${firstExtraExtraSmall.backgroundImage.fluid.srcWebp} 410w`,
				media: `(max-width: 524px)`,
			},
		],
		[
			{
				...second.backgroundImage.fluid,
				srcSet: `${second.backgroundImage.fluid.src} 1920w`,
				srcSetWebp: `${second.backgroundImage.fluid.srcWebp} 1920w`,
				media: `(min-width: 1350px)`,
			},
			{
				...secondmed.backgroundImage.fluid,
				srcSet: `${secondmed.backgroundImage.fluid.src} 1024w`,
				srcSetWebp: `${secondmed.backgroundImage.fluid.srcWebp} 1024w`,
				media: `(min-width: 1024px) and (max-width: 1349px)`,
			},
			{
				...secondsmall.backgroundImage.fluid,
				srcSet: `${secondsmall.backgroundImage.fluid.src} 768w`,
				srcSetWebp: `${secondsmall.backgroundImage.fluid.srcWebp} 768w`,
				media: `(min-width: 768px) and (max-width: 1023px)`,
			},
			{
				...secondExtraSmall.backgroundImage.fluid,
				srcSet: `${secondExtraSmall.backgroundImage.fluid.src} 525w`,
				srcSetWebp: `${secondExtraSmall.backgroundImage.fluid.srcWebp} 525w`,
				media: `(min-width: 525px) and (max-width: 767px)`,
			},
			{
				...secondExtraExtraSmall.backgroundImage.fluid,
				srcSet: `${secondExtraExtraSmall.backgroundImage.fluid.src} 410w`,
				srcSetWebp: `${secondExtraExtraSmall.backgroundImage.fluid.srcWebp} 410w`,
				media: `(max-width: 524px)`,
			},
		],
	]

	const bannerArray = [{ ...first }, { ...second }]
	const [activeBanner, setActiveBanner] = useState(bannerArray[0])
	const [activeSource, setActiveSource] = useState(sources[0])
	const [bannerIndex, setBannerIndex] = useState(0)
	const dotClickHandler = index => {
		setActiveSource(sources[index])
		setActiveBanner(bannerArray[index])
		setBannerIndex(index)
	}

	useEffect(() => {
		let interval

		interval = setInterval(() => {
			if (bannerIndex === 0) {
				setBannerIndex(1)
			} else if (bannerIndex >= bannerArray.length) {
				setBannerIndex(0)
			} else {
				let newIndex = bannerIndex + 1
				if (newIndex >= bannerArray.length) {
					setBannerIndex(0)
				} else {
					setBannerIndex(newIndex)
				}
			}
		}, 10000)

		return () => {
			clearInterval(interval)
		}
	})

	useEffect(() => {
		setActiveBanner(bannerArray[bannerIndex])
		setActiveSource(sources[bannerIndex])
	}, [bannerIndex])

	const options = {
		renderNode: {
			[BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
		},
	}

	const [pathname, setPathName] = useState('')
	useEffect(() => {
		const path = windowGlobal.location.pathname
		setPathName(path)
	}, [])
	const { globalLocale } = useLocale(pathname)

	const checkUrl =
		globalLocale !== 'en-AU'
			? activeBanner.secondActionUrl.includes(globalLocale)
			: null

	return (
		<>
			<Meta>
				<link rel="preload" href={img1WebP} as="image" />
			</Meta>
			<Section>
				<Wrapper>
					<Container>
						<TextWrapper>
							<Controls>
								<Nav role="navigation">
									<Dots role="menu">
										{bannerArray.map((_, index) => {
											return (
												<button
													className={bannerIndex === index ? 'isActive' : ''}
													key={index}
													tabIndex="-1"
													aria-label={index + 1}
													onClick={() => dotClickHandler(index)}
												>
													<VisuallyHidden>{index + 1}</VisuallyHidden>
												</button>
											)
										})}
									</Dots>
								</Nav>
							</Controls>
							<Text>
								<h2>
									{activeBanner.hook.split(',')[0]}
									<br />
									<span>{activeBanner.hook.split(',')[1]}</span>
								</h2>
								{documentToReactComponents(
									activeBanner.description.json,
									options
								)}
								<div className="group">
									<button className="solid">
										<Link
											className="solid"
											to={'/' + activeBanner.mainActionUrl}
										>
											{activeBanner.mainActionText}
										</Link>
									</button>
									{checkUrl === null || checkUrl !== false ? (
										<button className="outline">
											<Link
												className="outline"
												to={'/' + activeBanner.secondActionUrl}
											>
												{activeBanner.secondActionText}
											</Link>
										</button>
									) : (
										''
									)}
								</div>
							</Text>
						</TextWrapper>
					</Container>
				</Wrapper>
				<Image>
					<picture>
						<source srcSet={activeSource[0].srcSetWebp} type="image/webp" />
						<source srcSet={activeSource[0].srcSet} type="image/jpeg" />
						<img
							src={activeSource[0].src}
							title={activeSource[0].title}
							alt={activeSource[0].alt}
							loading={activeSource[0].loading}
						/>
					</picture>
				</Image>
			</Section>
		</>
	)
}

export default Hero2
